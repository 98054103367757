const codeMprefix = [
    {
        name: 'China',
        code: '86',
        prefix: 'CN',
        flag: 'https://www.worldometers.info/img/flags/small/tn_ch-flag.gif'
    },
    {
        name: 'Cambodia',
        code: '855',
        prefix: 'KH',
        flag: 'https://www.worldometers.info/img/flags/small/tn_cb-flag.gif'
    },
    {
        name: 'United States',
        code: '1',
        prefix: 'US',
        flag: 'https://www.worldometers.info/img/flags/small/tn_us-flag.gif'
    },
    {
        name: 'India',
        code: '91',
        prefix: 'IN',
        flag: 'https://www.worldometers.info/img/flags/small/tn_in-flag.gif'
    },
    {
        name: 'Russia',
        code: '7',
        prefix: 'RU',
        flag: 'https://www.worldometers.info/img/flags/small/tn_rs-flag.gif'
    },
    {
        name: 'Brazil',
        code: '55',
        prefix: 'BR',
        flag: 'https://www.worldometers.info/img/flags/small/tn_br-flag.gif'
    },
    {
        name: 'Japan',
        code: '81',
        prefix: 'JP',
        flag: 'https://www.worldometers.info/img/flags/small/tn_ja-flag.gif'
    },
    {
        name: 'Germany',
        code: '49',
        prefix: 'DE',
        flag: 'https://www.worldometers.info/img/flags/small/tn_gm-flag.gif'
    },
    {
        name: 'United Kingdom',
        code: '44',
        prefix: 'GB',
        flag: 'https://www.worldometers.info/img/flags/small/tn_uk-flag.gif'
    },
    {
        name: 'France',
        code: '33',
        prefix: 'FR',
        flag: 'https://www.worldometers.info/img/flags/small/tn_fr-flag.gif'
    },
    {
        name: 'Italy',
        code: '39',
        prefix: 'IT',
        flag: 'https://www.worldometers.info/img/flags/small/tn_it-flag.gif'
    },
    {
        name: 'Canada',
        code: '1',
        prefix: 'CA',
        flag: 'https://www.worldometers.info/img/flags/small/tn_ca-flag.gif'
    },
    {
        name: 'Australia',
        code: '61',
        prefix: 'AU',
        flag: 'https://www.worldometers.info/img/flags/small/tn_as-flag.gif'
    },
    {
        name: 'Mexico',
        code: '52',
        prefix: 'MX',
        flag: 'https://www.worldometers.info/img/flags/small/tn_mx-flag.gif'
    },
    {
        name: 'South Korea',
        code: '82',
        prefix: 'KR',
        flag: 'https://www.worldometers.info/img/flags/small/tn_ks-flag.gif'
    },
    {
        name: 'Spain',
        code: '34',
        prefix: 'ES',
        flag: 'https://www.worldometers.info/img/flags/small/tn_sp-flag.gif'
    },
    {
        name: 'Indonesia',
        code: '62',
        prefix: 'ID',
        flag: 'https://www.worldometers.info/img/flags/small/tn_id-flag.gif'
    },
    {
        name: 'Netherlands',
        code: '31',
        prefix: 'NL',
        flag: 'https://www.worldometers.info/img/flags/small/tn_nl-flag.gif'
    },
    {
        name: 'Saudi Arabia',
        code: '966',
        prefix: 'SA',
        flag: 'https://www.worldometers.info/img/flags/small/tn_sa-flag.gif'
    },
    {
        name: 'Turkey',
        code: '90',
        prefix: 'TR',
        flag: 'https://www.worldometers.info/img/flags/small/tn_tu-flag.gif'
    },
    {
        name: 'Switzerland',
        code: '41',
        prefix: 'CH',
        flag: 'https://www.worldometers.info/img/flags/small/tn_sz-flag.gif'
    },
    {
        name: 'Sweden',
        code: '46',
        prefix: 'SE',
        flag: 'https://www.worldometers.info/img/flags/small/tn_sw-flag.gif'
    },
    {
        name: 'Belgium',
        code: '32',
        prefix: 'BE',
        flag: 'https://www.worldometers.info/img/flags/small/tn_be-flag.gif'
    },
    {
        name: 'Poland',
        code: '48',
        prefix: 'PL',
        flag: 'https://www.worldometers.info/img/flags/small/tn_pl-flag.gif'
    },
    {
        name: 'Argentina',
        code: '54',
        prefix: 'AR',
        flag: 'https://www.worldometers.info/img/flags/small/tn_ar-flag.gif'
    },
    {
        name: 'Norway',
        code: '47',
        prefix: 'NO',
        flag: 'https://www.worldometers.info/img/flags/small/tn_no-flag.gif'
    },
    {
        name: 'Austria',
        code: '43',
        prefix: 'AT',
        flag: 'https://www.worldometers.info/img/flags/small/tn_au-flag.gif'
    },
    {
        name: 'Nigeria',
        code: '234',
        prefix: 'NG',
        flag: 'https://www.worldometers.info/img/flags/small/tn_ni-flag.gif'
    },
    {
        name: 'South Africa',
        code: '27',
        prefix: 'ZA',
        flag: 'https://www.worldometers.info/img/flags/small/tn_sf-flag.gif'
    },
    {
        name: 'Malaysia',
        code: '60',
        prefix: 'MY',
        flag: 'https://www.worldometers.info/img/flags/small/tn_my-flag.gif'
    },
    {
        name: 'Singapore',
        code: '65',
        prefix: 'SG',
        flag: 'https://www.worldometers.info/img/flags/small/tn_sn-flag.gif'
    },
    {
        name: 'Bangladesh',
        code: '880',
        prefix: 'BD',
        flag: 'https://www.worldometers.info/img/flags/small/tn_bg-flag.gif'
    },
    {
        name: 'Vietnam',
        code: '84',
        prefix: 'VN',
        flag: 'https://www.worldometers.info/img/flags/small/tn_vm-flag.gif'
    },
    {
        name: 'Thailand',
        code: '66',
        prefix: 'TH',
        flag: 'https://www.worldometers.info/img/flags/small/tn_th-flag.gif'
    },
    {
        name: 'Philippines',
        code: '63',
        prefix: 'PH',
        flag: 'https://www.worldometers.info/img/flags/small/tn_rp-flag.gif'
    },
    {
        name: 'Pakistan',
        code: '92',
        prefix: 'PK',
        flag: 'https://www.worldometers.info/img/flags/small/tn_pk-flag.gif'
    },
    {
        name: 'Egypt',
        code: '20',
        prefix: 'EG',
        flag: 'https://www.worldometers.info/img/flags/small/tn_eg-flag.gif'
    },
    {
        name: 'Israel',
        code: '972',
        prefix: 'IL',
        flag: 'https://www.worldometers.info/img/flags/small/tn_is-flag.gif'
    },
    {
        name: 'Chile',
        code: '56',
        prefix: 'CL',
        flag: 'https://www.worldometers.info/img/flags/small/tn_ci-flag.gif'
    },
    {
        name: 'New Zealand',
        code: '64',
        prefix: 'NZ',
        flag: 'https://www.worldometers.info/img/flags/small/tn_nz-flag.gif'
    },
    {
        name: 'Ireland',
        code: '353',
        prefix: 'IE',
        flag: 'https://www.worldometers.info/img/flags/small/tn_ei-flag.gif'
    },
    {
        name: 'Portugal',
        code: '351',
        prefix: 'PT',
        flag: 'https://www.worldometers.info/img/flags/small/tn_po-flag.gif'
    },
    {
        name: 'Greece',
        code: '30',
        prefix: 'GR',
        flag: 'https://www.worldometers.info/img/flags/small/tn_gr-flag.gif'
    },
    {
        name: 'Hungary',
        code: '36',
        prefix: 'HU',
        flag: 'https://www.worldometers.info/img/flags/small/tn_hu-flag.gif'
    },
    {
        name: 'Finland',
        code: '358',
        prefix: 'FI',
        flag: 'https://www.worldometers.info/img/flags/small/tn_fi-flag.gif'
    },
    {
        name: 'Czech Republic',
        code: '420',
        prefix: 'CZ',
        flag: 'https://www.worldometers.info/img/flags/small/tn_cz-flag.gif'
    },
    {
        name: 'Romania',
        code: '40',
        prefix: 'RO',
        flag: 'https://www.worldometers.info/img/flags/small/tn_ro-flag.gif'
    },
    {
        name: 'Portugal',
        code: '351',
        prefix: 'PT',
        flag: 'https://www.worldometers.info/img/flags/small/tn_po-flag.gif'
    },
    {
        name: 'Denmark',
        code: '45',
        prefix: 'DK',
        flag: 'https://www.worldometers.info/img/flags/small/tn_da-flag.gif'
    }
];

export default codeMprefix;