import { createApp } from "vue";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/css/style.css";

import api from "./api";


const app = createApp(App);
app.config.globalProperties.$api = api;
app.use(store);
app.use(router);
app.mount("#app");

app.config.errorHandler = (err, instance, info) => {
    // report error to tracking services
    console.error(err);
  };
