import axios from 'axios';

export default (url, method, params) => {
  let headers = {};
  if (method === "POST") {
    headers["content-type"] = "application/json";
  }
  const apiURL = `${process.env.VUE_APP_BASE_API}${url}`;
  return new Promise((resolve, reject) => {
    axios({
      url: apiURL,
      method: method,
      headers: headers,
      data: JSON.stringify(params),
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        // Enhanced error handling
        if (err.response) {
          // Server responded with a status other than 2xx
          console.error(`API Error: ${err.response.status} ${err.response.statusText}`);
          console.error('Response Data:', err.response.data);
        } else if (err.request) {
          // No response received from the server
          console.error('No response received:', err.request);
        } else {
          // Other errors (e.g., setting up the request)
          console.error('Error:', err.message);
        }
        // console.error('Request error:', err.response ? err.response.data : err.message);
        reject(err);
      });
  });
};
