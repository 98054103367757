<template>
    <div class="w-full h-[88px]">
        <div class="flex w-full z-[1000] fixed items-center justify-between bg-[#00a884] p-4 shadow-md">
            <div class="flex items-center space-x-2">
                <img @click="$router.push('/')" src="../assets/images/photo.jpg" alt="Logo" class="h-14 w-14 rounded-full">
                <div class="flex flex-col">
                    <div class="flex gap-1 items-center">
                        <span class="text-lg font-semibold text-white">WhatsApp</span>
                        <img src="../assets/images/duihao.png" class="h-4 w-4" alt="">
                    </div>
                    <span class="text-sm font-semibold text-white">WhatsApp 官方帳號</span>
                </div>
            </div>
            <div class="flex items-center space-x-2">
                <span class="text-xs bg-gray-200 p-1 rounded">CH</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'nav-bar',
    data() {
        return {
            showMore: false
        }
    },
}
</script>
<style lang="">

</style>