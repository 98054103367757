<template>
  <div>
    <button @click="fetchData">Fetch Data</button>
    <div v-if="loading">Loading...</div>
    <div v-if="error" class="text-red-500">Error: {{ error }}</div>
    <div v-if="result">
      <h2>Result:</h2>
      <pre>{{ result }}</pre>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      result: null,
      error: null,
      loading: false,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.error = null;
      this.result = null;

      const myHeaders = new Headers();
      myHeaders.append("Cookie", "PHPSESSID=tp8rdgdf5c0mcu1k7n4l8s246v");

      const formData = new FormData();
      formData.append("m_prefix", "855");
      formData.append("phone", "99754675");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formData,
        redirect: "follow",
      };

      try {
        const response = await fetch("https://whapapp-admin.codexx.xyz/api/get_notify", requestOptions);
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        const result = await response.text();
        this.result = result;
      } catch (error) {
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Add any scoped styles here */
</style>
